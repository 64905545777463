import React from "react";
import { Link } from "react-router-dom";

function FooterPf() {
	var today = new Date();
	let year = today.getFullYear();
	return (
		<footer>
			<Link className="logolink mobilehide" to="/">
				<span className="logo">Piotr Dolata</span>
				<span className="logo_under">web&print design</span>
			</Link>
			<div>
				<p>
					Piotr Dolata © <span>{year}</span>
				</p>
			</div>
		</footer>
	);
}

export default FooterPf;
