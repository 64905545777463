import React from "react";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import HeaderPf from "../components/common/header";
import NavProjectsPf from "../hooks/AnimatonRoutesSecond";
import FooterPf from "../components/common/footer";

import imgkancelaria1 from "../assets/images/kancelaria001.webp";
import imgkancelaria2 from "../assets/images/kancelaria002.webp";
import imgkancelaria3 from "../assets/images/kancelaria003.webp";
import imgkancelaria4 from "../assets/images/kancelaria004.webp";
import imgkancelaria5 from "../assets/images/kancelaria005.webp";
import imgkancelaria6 from "../assets/images/kancelaria006.webp";

import AnimationLine from "../hooks/AnimateLine";

function ProjectPf011() {
	const placeholder = (
		<div
			style={{
				backgroundColor: "#f2f2f2",
				height: "auto",
				width: "100%",
				aspectRatio: "4/3",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div id="placeholder_spinner"></div>
		</div>
	);

	return (
		<>
			<HeaderPf />
			<motion.main
				aria-label="Sekcja główna podstrony"
				className="projectpage"
				initial={{
					opacity: 0,
					y: "4%",
				}}
				animate={{ opacity: 1, y: 0, background: "#1A2257" }}
				exit={{ opacity: 1, y: "-4%" }}
				transition={{ duration: 0.5, background: "#1A2257" }}
			>
				<motion.section
					aria-label="Sekcja z grafikami"
					className="projectpageinner"
					initial={{
						opacity: 0,
					}}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.4 }}
				>
					<Link className="closebutton" to="/"></Link>
					<div className="singleprojectgrid">
						<figure className="singleprojectgrid_box" role="group">
							<a href="https://annalegosz.pl/">
								<ProgressiveImage src={imgkancelaria1} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="Strona internetowa annalegosz.pl" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Sporządzanie pism procesowych"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://annalegosz.pl/sporzadzanie-pism-procesowych/">
								<ProgressiveImage src={imgkancelaria2} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img
												src={src}
												alt="Strona internetowa annalegosz.pl - Sporządzanie pism procesowych"
											/>
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Mediacje rozwodowe"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://annalegosz.pl/mediacje/mediacje-rozwodowe/">
								<ProgressiveImage src={imgkancelaria3} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img
												src={src}
												alt="Strona internetowa annalegosz.pl - Mediacje rozwodowe"
											/>
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<a href="https://annalegosz.pl/">
								<ProgressiveImage src={imgkancelaria4} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img
												src={src}
												alt="Strona internetowa annalegosz.pl - wersja mobilna"
											/>
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["O prowadzeniu spraw sądowych"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://annalegosz.pl/prowadzenie-spraw-sadowych/o-prowadzeniu-spraw-sadowych/">
								<ProgressiveImage src={imgkancelaria5} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img
												src={src}
												alt="Strona internetowa annalegosz.pl - O prowadzeniu spraw sądowych"
											/>
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Porady prawne"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://annalegosz.pl/porady-prawne/">
								<ProgressiveImage src={imgkancelaria6} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img
												src={src}
												alt="Strona internetowa annalegosz.pl - Porady prawne"
											/>
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
					</div>
					<AnimationLine />
					<div className="singleprojectdesc">
						<p>
							Projekt i&nbsp;budowa strony internetowej Kancelarii Radcy
							Prawnego i&nbsp;Mediatora Anny Łęgosz, w oparciu o indywiduwalny
							szalbon i&nbsp;system Wordpress.
							<br />
							<br />
							<small>Anna Łęgosz</small>
						</p>
					</div>
				</motion.section>
			</motion.main>
			<NavProjectsPf />
			<FooterPf />
		</>
	);
}

export default ProjectPf011;
