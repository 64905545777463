import React from "react";
import { Route, Routes, Link, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import ProgressiveImage from "react-progressive-graceful-image";

import HeaderPf from "../components/common/header";
import FooterPf from "../components/common/footer";
import ToolsPf from "../components/specific/tools";
import ClientsPf from "../components/specific/clients";
// import ContactPf from "../pages/contact";

import Pio from "../assets/images/pio.png";
import "../index.css";

import AnimationLine2 from "../hooks/AnimateLine2";

import ProjectPf001 from "../pages/project001";
import ProjectPf002 from "../pages/project002";
import ProjectPf003 from "../pages/project003";
import ProjectPf004 from "../pages/project004";
import ProjectPf005 from "../pages/project005";
import ProjectPf006 from "../pages/project006";
import ProjectPf007 from "../pages/project007";
import ProjectPf008 from "../pages/project008";
import ProjectPf009 from "../pages/project009";
import ProjectPf010 from "../pages/project010";
import ProjectPf011 from "../pages/project011";
import ProjectPf012 from "../pages/project012";
import ProjectPf013 from "../pages/project013";
import ProjectPf014 from "../pages/project014";

import img1 from "../assets/images/katalog-lodzkie-tile.webp";
import img2 from "../assets/images/issei-tile.webp";
import img3 from "../assets/images/rotwl-tile.webp";
import img4 from "../assets/images/kit-tile.webp";
import img5 from "../assets/images/piwny-tile.webp";
import img6 from "../assets/images/osadnicy-tile.webp";
import img7 from "../assets/images/visit-tile.webp";
import img8 from "../assets/images/obiekty-techniki-tile.webp";
import img9 from "../assets/images/cx-tile.webp";
import img10 from "../assets/images/klientocentryczni-tile.webp";
import img11 from "../assets/images/kancelaria-tile.webp";
import img12 from "../assets/images/sprawozdanie-tile.webp";
import img13 from "../assets/images/stn-tile.webp";
import img14 from "../assets/images/umed-tile.webp";

function HomePf() {
	const location = useLocation();

	const placeholder = (
		<div
			style={{
				backgroundColor: "#f2f2f2",
				height: "auto",
				width: "100%",
				aspectRatio: "4/3",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div id="placeholder_spinner"></div>
		</div>
	);

	return (
		<>
			<HeaderPf />
			<motion.main
				role="main"
				aria-label="Sekcja główna portfolio"
				initial={{
					opacity: 0,
				}}
				animate={{ opacity: 1 }}
				exit={{ opacity: 1 }}
				transition={{ duration: 0.7 }}
			>
				<section className="mainsection" aria-label="Sekcja z projektami">
					<nav className="projectsgrid" aria-label="Projekty Piotra Dolaty">
						<ul aria-label="Lista projektów Piotra Dolaty">
							<li aria-label="Strona internetowa Osadnicy">
								<Link
									to="osadnicy"
									aria-label="Zobacz stronę internetową Osadnicy"
								>
									<ProgressiveImage src={img6} placeholder="">
										{(src, loading) => {
											return loading ? (
												placeholder
											) : (
												<img src={src} alt="Strona internetowa Osadnicy" />
											);
										}}
									</ProgressiveImage>
								</Link>
								<div className="projectsgrid_goto" aria-disabled="true">
									<div
										className="projectsgrid_goto_icon"
										aria-disabled="true"
									></div>
								</div>
								<div
									disabled
									className="projectsgrid_text"
									aria-label="Strona internetowa Osadnicy"
								>
									Osadnicy. Śladami "użytecznych cudzoziemców" przez region
									łódzki
									<span
										className="strona"
										aria-label="Rodzaj zadania: strona internetowa"
									>
										Strona internetowa
									</span>
								</div>
							</li>
							<li aria-label="The 15th International Conference of ISSEI">
								<Link
									to="konferencja-issei"
									aria-label="Zobacz identyfikację wizualną The 15th International Conference of ISSEI"
								>
									<ProgressiveImage src={img2} placeholder="">
										{(src, loading) => {
											return loading ? (
												placeholder
											) : (
												<img
													src={src}
													alt="Identyfikacja wizualna The 15th International Conference of ISSEI"
												/>
											);
										}}
									</ProgressiveImage>
								</Link>
								<div className="projectsgrid_goto" aria-disabled="true">
									<div
										className="projectsgrid_goto_icon"
										aria-disabled="true"
									></div>
								</div>
								<div
									disabled
									className="projectsgrid_text"
									aria-label="The 15th International Conference of ISSEI"
								>
									The 15th International Conference of&nbsp;ISSEI
									<span
										className="identyfikacja"
										aria-label="Rodzaj zadania: opracowanie identyfikacji wizualnej"
									>
										Identyfikacja wizualna
									</span>
								</div>
							</li>
							<li aria-label="Opracowanie graficzne materiałów promocyjnych Regionalnej Organizacji Turystycznej Województwa Łódzkiego">
								<Link
									to="rotwl-identyfikacja"
									aria-label="Zobacz materiały graficzne Regionalnej Organizacji Turystycznej Województwa Łódzkiego"
								>
									<ProgressiveImage src={img3} placeholder="">
										{(src, loading) => {
											return loading ? (
												placeholder
											) : (
												<img
													src={src}
													alt="Opracowanie graficzne materiałów promocyjnych Regionalnej Organizacji Turystycznej Województwa Łódzkiego"
												/>
											);
										}}
									</ProgressiveImage>
								</Link>
								<div className="projectsgrid_goto" aria-disabled="true">
									<div
										className="projectsgrid_goto_icon"
										aria-disabled="true"
									></div>
								</div>
								<div
									disabled
									className="projectsgrid_text"
									aria-label="Opracowanie graficzne materiałów promocyjnych Regionalnej Organizacji Turystycznej Województwa Łódzkiego"
								>
									Regionalna Organizacja Turystyczna Województwa Łódzkiego
									<span
										className="identyfikacja"
										aria-label="Rodzaj zadania: opracowanie graficzne materiałów promocyjnych"
									>
										Identyfikacja wizualna
									</span>
								</div>
							</li>
							<li aria-label="Obiekty techniki województwa łódzkiego">
								<Link
									to="obiekty-techniki"
									aria-label="Zobacz obiekty techniki województwa łódzkiego"
								>
									<ProgressiveImage src={img8} placeholder="">
										{(src, loading) => {
											return loading ? (
												placeholder
											) : (
												<img
													src={src}
													alt="Obiekty techniki województwa łódzkiego"
												/>
											);
										}}
									</ProgressiveImage>
								</Link>
								<div className="projectsgrid_goto" aria-disabled="true">
									<div
										className="projectsgrid_goto_icon"
										aria-disabled="true"
									></div>
								</div>
								<div
									disabled
									className="projectsgrid_text"
									aria-label="Obiekty techniki województwa łódzkiego"
								>
									Obiekty techniki województwa łódzkiego - seria publikacji
									krajoznawczych
									<span
										className="sklad"
										aria-label="Rodzaj zadania: skład publikacji"
									>
										Skład publikacji
									</span>
								</div>
							</li>
							<li aria-label="Strona internetowa Kancelarii Radcy Prawnego i Mediatora Anna Łęgosz">
								<Link
									to="kancelaria"
									aria-label="Zobacz stronę internetową Kancelarii Radcy Prawnego i Mediatora Anna Łęgosz"
								>
									<ProgressiveImage src={img11} placeholder="">
										{(src, loading) => {
											return loading ? (
												placeholder
											) : (
												<img
													src={src}
													alt="Strona internetowa Kancelarii Radcy Prawnego i Mediatora Anna Łęgosz"
												/>
											);
										}}
									</ProgressiveImage>
								</Link>
								<div className="projectsgrid_goto" aria-disabled="true">
									<div
										className="projectsgrid_goto_icon"
										aria-disabled="true"
									></div>
								</div>
								<div
									disabled
									className="projectsgrid_text"
									aria-label="Strona internetowa Kancelarii Radcy Prawnego i Mediatora Anna Łęgosz"
								>
									Kancelaria Radcy Prawnego i&nbsp;Mediatora Anna&nbsp;Łęgosz
									<span
										className="strona"
										aria-label="Rodzaj zadania: strona internetowa"
									>
										Strona internetowa
									</span>
								</div>
							</li>
							<li aria-label="Przewodnik turystyczy po województwie łódzkim">
								<Link
									to="katalog-lodzkie"
									aria-label="Zobacz przewodnik turystyczny po województwie łódzkim"
								>
									<ProgressiveImage src={img1} placeholder="" role="img">
										{(src, loading) => {
											return loading ? (
												placeholder
											) : (
												<img
													src={src}
													alt="Przewodnik turystyczny po województwie łódzkim"
												/>
											);
										}}
									</ProgressiveImage>
								</Link>
								<div className="projectsgrid_goto" aria-disabled="true">
									<div
										className="projectsgrid_goto_icon"
										aria-disabled="true"
									></div>
								</div>
								<div
									disabled
									className="projectsgrid_text"
									aria-label="Przewodnik turystyczny po województwie łódzkim"
								>
									Przewodnik turystyczny po&nbsp;województwie łódzkim
									<span
										className="sklad"
										aria-label="Rodzaj zadania: skład publikacji"
									>
										Skład publikacji
									</span>
								</div>
							</li>
							<li aria-label="Strona internetowa Studenckie Towarzystwo Naukowe Uniwersytetu Medycznego w Łodzi">
								<Link
									to="studenckie-towarzystwo-naukowe"
									aria-label="Zobacz stronę internetową Studenckiego Towarzystwa Naukowego Uniwersytetu Medycznego w Łodzi"
								>
									<ProgressiveImage src={img13} placeholder="">
										{(src, loading) => {
											return loading ? (
												placeholder
											) : (
												<img
													src={src}
													alt="Strona internetowa Studenckie Towarzystwo Naukowe Uniwersytetu Medycznego w Łodzi"
												/>
											);
										}}
									</ProgressiveImage>
								</Link>
								<div className="projectsgrid_goto" aria-disabled="true">
									<div
										className="projectsgrid_goto_icon"
										aria-disabled="true"
									></div>
								</div>
								<div
									disabled
									className="projectsgrid_text"
									aria-label="Strona internetowa Studenckie Towarzystwo Naukowe Uniwersytetu Medycznego w Łodzi"
								>
									Studenckie Towarzystwo Naukowe Uniwersytetu Medycznego w Łodzi
									<span
										className="strona"
										aria-label="Rodzaj zadania: strona internetowa"
									>
										Strona internetowa
									</span>
								</div>
							</li>
							<li aria-label="Strony internetowe Uniwersytetu Medycznego w Łodzi">
								<Link
									to="uniwersytet-medyczny"
									aria-label="Zobacz strony internetowe Uniwersytetu Medycznego w Łodzi"
								>
									<ProgressiveImage src={img14} placeholder="">
										{(src, loading) => {
											return loading ? (
												placeholder
											) : (
												<img
													src={src}
													alt="Strony internetowe Uniwersytetu Medycznego w Łodzi"
												/>
											);
										}}
									</ProgressiveImage>
								</Link>
								<div className="projectsgrid_goto" aria-disabled="true">
									<div
										className="projectsgrid_goto_icon"
										aria-disabled="true"
									></div>
								</div>
								<div
									disabled
									className="projectsgrid_text"
									aria-label="Strony internetowe Uniwersytetu Medycznego w Łodzi"
								>
									Strony internetowe Uniwersytetu Medycznego w Łodzi
									<span
										className="strona"
										aria-label="Rodzaj zadania: wdrożenie wspólnego layoutu"
									>
										Wdrożenie wspólnego layoutu
									</span>
								</div>
							</li>
							<li aria-label="Strona internetowa CX Institute">
								<Link
									to="cx-institute"
									aria-label="Zobacz stronę internetową CX Institute"
								>
									<ProgressiveImage src={img9} placeholder="">
										{(src, loading) => {
											return loading ? (
												placeholder
											) : (
												<img src={src} alt="Strona internetowa CX Institute" />
											);
										}}
									</ProgressiveImage>
								</Link>
								<div className="projectsgrid_goto" aria-disabled="true">
									<div
										className="projectsgrid_goto_icon"
										aria-disabled="true"
									></div>
								</div>
								<div
									disabled
									className="projectsgrid_text"
									aria-label="Strona internetowa CX Institute"
								>
									CX Institute - zmierz swój poziom klientocentryczności
									<span
										className="strona"
										aria-label="Rodzaj zadania: strona internetowa"
									>
										Strona internetowa
									</span>
								</div>
							</li>
							<li aria-label="Sprawozdanie z działalności zarządu Związku Województw RP">
								<Link
									to="sprawozdanie-2020"
									aria-label="Zobacz projekt graficzny Sprawozdania z działalności zarządu Związku Województw RP"
								>
									<ProgressiveImage src={img12} placeholder="">
										{(src, loading) => {
											return loading ? (
												placeholder
											) : (
												<img
													src={src}
													alt="Sprawozdanie z działalności zarządu Związku Województw RP"
												/>
											);
										}}
									</ProgressiveImage>
								</Link>
								<div className="projectsgrid_goto" aria-disabled="true">
									<div
										className="projectsgrid_goto_icon"
										aria-disabled="true"
									></div>
								</div>
								<div
									disabled
									className="projectsgrid_text"
									aria-label="Sprawozdanie z działalności zarządu Związku Województw RP"
								>
									Sprawozdanie z działalności zarządu Związku Województw RP
									<span
										className="sklad"
										aria-label="Rodzaj zadania: skład publikacji"
									>
										Skład publikacji
									</span>
								</div>
							</li>
							<li aria-label="Identyfikacja wizualna konferencji Kultura i Turystyka">
								<Link
									to="kultura-i-turystyka"
									aria-label="Zobacz identyfikację wizualną konferencji Kultura i Turystyka"
								>
									<ProgressiveImage src={img4} placeholder="">
										{(src, loading) => {
											return loading ? (
												placeholder
											) : (
												<img
													src={src}
													alt="Identyfikacja wizualna konferencji Kultura i Turystyka"
												/>
											);
										}}
									</ProgressiveImage>
								</Link>
								<div className="projectsgrid_goto" aria-disabled="true">
									<div
										className="projectsgrid_goto_icon"
										aria-disabled="true"
									></div>
								</div>
								<div
									disabled
									className="projectsgrid_text"
									aria-label="Identyfikacja wizualna konferencji Kultura i Turystyka"
								>
									Konferencja Kultura i Turystyka
									<span
										className="identyfikacja"
										aria-label="Rodzaj zadania: Identyfikacja wizualna konferencji"
									>
										Identyfikacja wizualna
									</span>
								</div>
							</li>
							<li aria-label="Strona internetowa Klientocentryczni">
								<Link
									to="klientocentryczni"
									aria-label="Zobacz stronę internetową Klientocentryczni"
								>
									<ProgressiveImage src={img10} placeholder="">
										{(src, loading) => {
											return loading ? (
												placeholder
											) : (
												<img
													src={src}
													alt="Strona internetowa Klientocentryczni"
												/>
											);
										}}
									</ProgressiveImage>
								</Link>
								<div className="projectsgrid_goto" aria-disabled="true">
									<div
										className="projectsgrid_goto_icon"
										aria-disabled="true"
									></div>
								</div>
								<div
									disabled
									className="projectsgrid_text"
									aria-label="Strona internetowa Klientocentryczni"
								>
									Klientocentryczni - design thinking, service design, design
									sprint
									<span
										className="strona"
										aria-label="Rodzaj zadania: strona internetowa"
									>
										Strona internetowa
									</span>
								</div>
							</li>
							<li aria-label="Identyfikacja wizualna marki Piwny Marketing">
								<Link
									to="piwny-marketing"
									aria-label="Zobacz identyfikację wizualną marki Piwny Marketing"
								>
									<ProgressiveImage src={img5} placeholder="">
										{(src, loading) => {
											return loading ? (
												placeholder
											) : (
												<img
													src={src}
													alt="Identyfikacja wizualna marki Piwny Marketing"
												/>
											);
										}}
									</ProgressiveImage>
								</Link>
								<div className="projectsgrid_goto" aria-disabled="true">
									<div
										className="projectsgrid_goto_icon"
										aria-disabled="true"
									></div>
								</div>
								<div
									disabled
									className="projectsgrid_text"
									aria-label="Identyfikacja wizualna marki Piwny Marketing"
								>
									Piwny Marketing
									<span
										className="identyfikacja"
										aria-label="Rodzaj zadania: identyfikacja wizualna marki"
									>
										Identyfikacja wizualna
									</span>
								</div>
							</li>
							<li aria-label="Katalog ofert turystycznych Visit Łódzkie">
								<Link
									to="visit-lodzkie"
									aria-label="Zobacz katalog ofert turystycznych Visit Łódzkie"
								>
									<ProgressiveImage src={img7} placeholder="">
										{(src, loading) => {
											return loading ? (
												placeholder
											) : (
												<img
													src={src}
													alt="Katalog ofert turystycznych Visit Łódzkie"
												/>
											);
										}}
									</ProgressiveImage>
								</Link>
								<div className="projectsgrid_goto" aria-disabled="true">
									<div
										className="projectsgrid_goto_icon"
										aria-disabled="true"
									></div>
								</div>
								<div
									disabled
									className="projectsgrid_text"
									aria-label="Katalog ofert turystycznych Visit Łódzkie"
								>
									Katalog ofert turystycznych z województwa łódzkiego Visit
									Łódzkie
									<span
										className="sklad"
										aria-label="Rodzaj zadania: skład publikacji"
									>
										Skład publikacji
									</span>
								</div>
							</li>
							<li aria-label="Tu może być Twój projekt" class="mobilehide">
								<Link to="mailto:kontakt@piotrekd.pl">
									<div className="projectsgrid_yourproject">
										<div className="projectsgrid_yourproject_plus"></div>
									</div>
								</Link>
								<div className="projectsgrid_text">
									Tu może być Twój projekt!
								</div>
							</li>
						</ul>
					</nav>
					<AnimatePresence mode="wait">
						<Routes location={location} key={location.pathname}>
							<Route exact path="katalog-lodzkie" element={<ProjectPf001 />} />
							<Route
								exact
								path="konferencja-issei"
								element={<ProjectPf002 />}
							/>
							<Route
								exact
								path="rotwl-identyfikacja"
								element={<ProjectPf003 />}
							/>
							<Route
								exact
								path="kultura-i-turystyka"
								element={<ProjectPf004 />}
							/>
							<Route exact path="piwny-marketing" element={<ProjectPf005 />} />
							<Route exact path="osadnicy" element={<ProjectPf006 />} />
							<Route exact path="visit-lodzkie" element={<ProjectPf007 />} />
							<Route exact path="obiekty-techniki" element={<ProjectPf008 />} />
							<Route exact path="cx-institute" element={<ProjectPf009 />} />
							<Route
								exact
								path="klientocentryczni"
								element={<ProjectPf010 />}
							/>
							<Route exact path="kancelaria" element={<ProjectPf011 />} />
							<Route
								exact
								path="sprawozdanie-2020"
								element={<ProjectPf012 />}
							/>
							<Route
								exact
								path="studenckie-towarzystwo-naukowe"
								element={<ProjectPf013 />}
							/>
							<Route
								exact
								path="uniwersytet-medyczny"
								element={<ProjectPf014 />}
							/>
							{/* <Route exact path="kontakt" element={<ContactPf />} /> */}
						</Routes>
					</AnimatePresence>
				</section>
				<section className="piotrsection">
					<div className="main_box">
						<div className="main_box_left"></div>
						<div className="main_box_right">
							<div className="main_box_right_gradient"></div>
						</div>
						<div className="main_box_top">
							<div className="main_box_top_left">
								<p>Cześć</p>
								<p lang="pl">
									Nazywam się Piotr Dolata. Od 15 lat zajmuję się projektowaniem
									graficznym. Specjalizuję się w&nbsp;grafice{" "}
									<span className="marker">internetowej</span> oraz grafice
									projektowanej na potrzeby{" "}
									<span className="marker">druku</span>. Przygotowuję
									identyfikację wizualną dla firm, instytucji i eventów. Buduję{" "}
									<span className="marker">strony</span> i{" "}
									<span className="marker">sklepy</span> internetowe&nbsp;w
									oparciu o&nbsp;system WordPress, na szablonach projektowanych
									pod indywidualne potrzeby.{" "}
									<span className="marker">Administruję</span> gotowymi
									stronami. Kompetencje frontendowe rozwijam z wykorzystaniem
									frameworka React.
								</p>
								<p lang="pl">
									Wyróżnia mnie wszechstronne doświadczenie i umiejętność
									szerokiego spojrzenia na zagadnienia projektowe. Cechuje mnie
									dbałość o szczegóły i wysokie zaangażowanie w pracę.
								</p>
								<p lang="pl">
									Zapraszam do kontaktu:&nbsp;
									<a className="marker" href="mailto:kontakt@piotrekd.pl">
										kontakt@piotrekd.pl
									</a>
								</p>
							</div>
							<div className="main_box_top_right">
								<img
									alt="Piotr Dolata"
									src={Pio}
									width="auto"
									height="500"
									loading="lazy"
								/>
							</div>
						</div>
					</div>
				</section>
				<section className="undersection">
					<div className="subtitle">
						<div className="subtitle_box">
							<h2>Zaufali mi</h2>
						</div>
					</div>
					<div className="clients">
						<div className="clients_box">
							<ClientsPf />
						</div>
					</div>
				</section>
				<section className="undersection">
					<div className="subtitle">
						<div className="subtitle_box">
							<h2>Moje narzędzia</h2>
						</div>
					</div>
					<AnimationLine2 />
					<div className="mytools">
						<div className="mytools_box">
							<ToolsPf />
						</div>
					</div>
				</section>
				{/* <section className="undersection">
					<div className="subtitle">
						<div className="subtitle_box">
							<h2>Kontakt</h2>
						</div>
					</div>
				</section> */}
			</motion.main>
			<FooterPf />
		</>
	);
}

export default HomePf;
