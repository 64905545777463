import React from "react";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import HeaderPf from "../components/common/header";
import NavProjectsPf from "../hooks/AnimatonRoutesSecond";
import FooterPf from "../components/common/footer";

import imgpiwny1 from "../assets/images/piwny-logo.webp";
import imgpiwny2 from "../assets/images/piwny-rollup.webp";
import imgpiwny3 from "../assets/images/piwny-wiz.webp";
import imgpiwny4 from "../assets/images/piwny-piwo.webp";

import AnimationLine from "../hooks/AnimateLine";

function ProjectPf005() {
	const placeholder = (
		<div
			style={{
				backgroundColor: "#f2f2f2",
				height: "auto",
				width: "100%",
				aspectRatio: "4/3",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div id="placeholder_spinner"></div>
		</div>
	);

	return (
		<>
			<HeaderPf />
			<motion.main
				aria-label="Sekcja główna podstrony"
				className="projectpage"
				initial={{
					opacity: 0,
					y: "4%",
				}}
				animate={{ opacity: 1, y: 0, background: "#FFD300" }}
				exit={{ opacity: 1, y: "-4%" }}
				transition={{ duration: 0.5, background: "#FFD300" }}
			>
				<motion.section
					aria-label="Sekcja z grafikami"
					className="projectpageinner"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.4 }}
				>
					<Link className="closebutton" to="/"></Link>
					<div className="singleprojectgrid">
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Logo"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgpiwny1} placeholder="">
								{(src, loading) => {
									return loading ? placeholder : <img src={src} alt="Logo" />;
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Rollup"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgpiwny2} placeholder="">
								{(src, loading) => {
									return loading ? placeholder : <img src={src} alt="Rollup" />;
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Wizytówka"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgpiwny3} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Wizytówka" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<ProgressiveImage src={imgpiwny4} placeholder="">
								{(src, loading) => {
									return loading ? placeholder : <img src={src} alt="Piwo" />;
								}}
							</ProgressiveImage>
						</figure>
					</div>
					<AnimationLine />
					<div className="singleprojectdesc">
						<p>
							Projekt logo i baneru reklamowego dla marki Piwny Marketing
							<br />
							<br />
							<small>Piwny Marketing Tomasz Prośniak</small>
						</p>
					</div>
				</motion.section>
			</motion.main>
			<NavProjectsPf />
			<FooterPf />
		</>
	);
}

export default ProjectPf005;
