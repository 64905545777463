import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./assets/fonts/SpaceGrotesk-Light.ttf";
import "./assets/fonts/SpaceGrotesk-Regular.ttf";
import "./assets/fonts/SpaceGrotesk-Medium.ttf";
import "./assets/fonts/SpaceGrotesk-SemiBold.ttf";
import "./assets/fonts/SpaceGrotesk-Bold.ttf";

const root = ReactDOM.createRoot(document.querySelector("body"));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
