import React from "react";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import HeaderPf from "../components/common/header";
import NavProjectsPf from "../hooks/AnimatonRoutesSecond";
import FooterPf from "../components/common/footer";

import imgumed1 from "../assets/images/umed001.webp";
import imgumed2 from "../assets/images/umed002.webp";
import imgumed3 from "../assets/images/umed003.webp";
import imgumed4 from "../assets/images/umed004.webp";
import imgumed5 from "../assets/images/umed005.webp";
import imgumed6 from "../assets/images/umed006.webp";
import imgumed7 from "../assets/images/umed007.webp";
import imgumed8 from "../assets/images/umed008.webp";

import AnimationLine from "../hooks/AnimateLine";

function ProjectPf014() {
	const placeholder = (
		<div
			style={{
				backgroundColor: "#f2f2f2",
				height: "auto",
				width: "100%",
				aspectRatio: "4/3",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div id="placeholder_spinner"></div>
		</div>
	);

	return (
		<>
			<HeaderPf />
			<motion.main
				aria-label="Sekcja główna podstrony"
				className="projectpage"
				initial={{
					opacity: 0,
					y: "4%",
				}}
				animate={{ opacity: 1, y: 0, background: "#128AF9" }}
				exit={{ opacity: 1, y: "-4%" }}
				transition={{ duration: 0.5, background: "#128AF9" }}
			>
				<motion.section
					aria-label="Sekcja z grafikami"
					className="projectpageinner"
					initial={{
						opacity: 0,
					}}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.4 }}
				>
					<Link className="closebutton" to="/"></Link>
					<div className="singleprojectgrid">
						<figure className="singleprojectgrid_box" role="group">
							<a href="https://studymed.umed.pl/">
								<ProgressiveImage src={imgumed1} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="studymed.umed.pl" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["molecolab.umed.pl"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://molecolab.umed.pl/">
								<ProgressiveImage src={imgumed2} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="molecolab.umed.pl" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["welcomecentre.umed.pl"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://molecolab.umed.pl/">
								<ProgressiveImage src={imgumed3} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="welcomecentre.umed.pl" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["ummagazyn.umed.pl"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://ummagazyn.umed.pl/">
								<ProgressiveImage src={imgumed4} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="ummagazyn.umed.pl" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["studymed.umed.pl - Newsy"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://studymed.umed.pl/news/">
								<ProgressiveImage src={imgumed5} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="studymed.umed.pl - Newsy" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["welcomecentre.umed.pl - Plane"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://welcomecentre.umed.pl/how-to-get-to-lodz/plane/">
								<ProgressiveImage src={imgumed6} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="welcomecentre.umed.pl - Plane" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Wersje mobilne stron"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgumed7} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Wersje mobilne stron" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Wersje mobilne stron"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgumed8} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Wersje mobilne stron" />
									);
								}}
							</ProgressiveImage>
						</figure>
					</div>
					<AnimationLine />
					<div className="singleprojectdesc">
						<p>
							Przygotowanie i wdrożenie layoutu zgodnego z identyfikacją
							wizualną Uniwersytetu Medycznego w Łodzi dla stron:
							studymed.umed.pl, molecolab.umed.pl, ummagazyn.umed.pl i
							welcomcentre.umed.pl.
							<br />
							<br />
							<small>Uniwersytet Medyczny w Łodzi</small>
						</p>
					</div>
				</motion.section>
			</motion.main>
			<NavProjectsPf />
			<FooterPf />
		</>
	);
}

export default ProjectPf014;
