import React from "react";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import HeaderPf from "../components/common/header";
import NavProjectsPf from "../hooks/AnimatonRoutesSecond";
import FooterPf from "../components/common/footer";

import imgklient1 from "../assets/images/klientocentryczni001.webp";
import imgklient2 from "../assets/images/klientocentryczni002.webp";
import imgklient3 from "../assets/images/klientocentryczni003.webp";
import imgklient4 from "../assets/images/klientocentryczni004.webp";
import imgklient5 from "../assets/images/klientocentryczni005.webp";
import imgklient6 from "../assets/images/klientocentryczni006.webp";

import AnimationLine from "../hooks/AnimateLine";

function ProjectPf010() {
	const placeholder = (
		<div
			style={{
				backgroundColor: "#f2f2f2",
				height: "auto",
				width: "100%",
				aspectRatio: "4/3",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div id="placeholder_spinner"></div>
		</div>
	);
	return (
		<>
			<HeaderPf />
			<motion.main
				aria-label="Sekcja główna podstrony"
				className="projectpage"
				initial={{
					opacity: 0,
					y: "4%",
				}}
				animate={{ opacity: 1, y: 0, background: "#fece41" }}
				exit={{ opacity: 1, y: "-4%" }}
				transition={{ duration: 0.5, background: "#fece41" }}
			>
				<motion.section
					aria-label="Sekcja z grafikami"
					className="projectpageinner"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.4 }}
				>
					<Link className="closebutton" to="/"></Link>
					<div className="singleprojectgrid">
						<figure className="singleprojectgrid_box" role="group">
							<a href="https://klientocentryczni.pl/">
								<ProgressiveImage src={imgklient1} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img
												src={src}
												alt="Strona internetowa klientocentryczni.pl"
											/>
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Strona główna"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://klientocentryczni.pl/">
								<ProgressiveImage src={imgklient2} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img
												src={src}
												alt="klientocentryczni.pl - strona główna"
											/>
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["O nas"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://klientocentryczni.pl/o-nas-1/">
								<ProgressiveImage src={imgklient3} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="klientocentryczni.pl - o nas" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Szkolenia"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://klientocentryczni.pl/oferta/szkolenia-online-i-offline/">
								<ProgressiveImage src={imgklient4} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="klientocentryczni.pl - szkolenia" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Kontakt"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://klientocentryczni.pl/#contact">
								<ProgressiveImage src={imgklient5} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="klientocentryczni.pl - kontakt" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<a href="https://klientocentryczni.pl/">
								<ProgressiveImage src={imgklient6} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img
												src={src}
												alt="klientocentryczni.pl - wersja mobilna"
											/>
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
					</div>
					<AnimationLine />
					<div className="singleprojectdesc">
						<p>
							Wdrożenie nowego layoutu na stronie internetowej
							klientocentryczni.pl
							<br />
							<br />
							<small>Klientocentryczni</small>
						</p>
					</div>
				</motion.section>
			</motion.main>
			<NavProjectsPf />
			<FooterPf />
		</>
	);
}

export default ProjectPf010;
