import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AnimatedRoutes from "./hooks/AnimationRoutes";

import ScrollToTop from "./hooks/scrolltotop";

function App() {
	return (
		<Router>
			<ScrollToTop />
			<AnimatedRoutes />
		</Router>
	);
}

export default App;
