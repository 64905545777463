import React from "react";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import HeaderPf from "../components/common/header";
import NavProjectsPf from "../hooks/AnimatonRoutesSecond";
import FooterPf from "../components/common/footer";

import imgkit1 from "../assets/images/kit-sala.webp";
import imgkit2 from "../assets/images/kit-identyfikator.webp";
import imgkit3 from "../assets/images/kit-rollup.webp";
import imgkit4 from "../assets/images/kit-ksiazka.webp";
import imgkit5 from "../assets/images/kit-torba.webp";
import imgkit6 from "../assets/images/kit-torbalen.webp";
import imgkit7 from "../assets/images/kit-segregator.webp";
import imgkit8 from "../assets/images/kit-segregator2.webp";
import imgkit9 from "../assets/images/kit-rollup2.webp";
import imgkit10 from "../assets/images/kit-plakat3.webp";

import AnimationLine from "../hooks/AnimateLine";

function ProjectPf004() {
	const placeholder = (
		<div
			style={{
				backgroundColor: "#f2f2f2",
				height: "auto",
				width: "100%",
				aspectRatio: "4/3",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div id="placeholder_spinner"></div>
		</div>
	);

	return (
		<>
			<HeaderPf />
			<motion.main
				aria-label="Sekcja główna podstrony"
				className="projectpage"
				initial={{
					opacity: 0,
					y: "4%",
				}}
				animate={{ opacity: 1, y: 0, background: "#cbbe90" }}
				exit={{ opacity: 1, y: "-4%" }}
				transition={{ duration: 0.5, background: "#cbbe90" }}
			>
				<motion.section
					aria-label="Sekcja z grafikami"
					className="projectpageinner"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.4 }}
				>
					<Link className="closebutton" to="/"></Link>
					<div className="singleprojectgrid">
						<figure className="singleprojectgrid_box" role="group">
							<ProgressiveImage src={imgkit1} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Sala konferencyjna" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Identyfikator"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgkit2} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Identyfikator" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Rollup"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgkit3} placeholder="">
								{(src, loading) => {
									return loading ? placeholder : <img src={src} alt="Rollup" />;
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Książka z artykułami"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgkit4} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Książka z artykułami" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Papierowa torba"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgkit5} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Papierowa torba" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Torba lniana"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgkit6} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Torba lniana" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Segregator"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgkit7} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Segregator" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Segregator"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgkit8} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Segregator" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Rollup"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgkit9} placeholder="">
								{(src, loading) => {
									return loading ? placeholder : <img src={src} alt="Rollup" />;
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Citylight"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgkit10} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Citylight" />
									);
								}}
							</ProgressiveImage>
						</figure>
					</div>
					<AnimationLine />
					<div className="singleprojectdesc">
						<p>
							Identyfikacja wizualna konferencji Kultura i Turystyka
							organizowanej w latach 2011-2016, obejmująca opracowanie graficzne
							plakatów, rollapów, identyfikatorów, okładki książki, notesów,
							smyczy konferencyjnych, segregatorów, toreb papierowych
							i&nbsp;lnianych, długopisów i innych materiałów promocyjnych
							<br />
							<br />
							<small>
								Regionalna Organizacja Turystyczna Województwa Łódzkiego
							</small>
						</p>
					</div>
				</motion.section>
			</motion.main>
			<NavProjectsPf />
			<FooterPf />
		</>
	);
}

export default ProjectPf004;
