import React from "react";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import HeaderPf from "../components/common/header";
import NavProjectsPf from "../hooks/AnimatonRoutesSecond";
import FooterPf from "../components/common/footer";

import imgissei1 from "../assets/images/issei-broszura.webp";
import imgissei2 from "../assets/images/issei-teczka.webp";
import imgissei3 from "../assets/images/issei-zaproszenie.webp";
import imgissei4 from "../assets/images/issei-rollup.webp";

import AnimationLine from "../hooks/AnimateLine";

function ProjectPf002() {
	const placeholder = (
		<div
			style={{
				backgroundColor: "#f2f2f2",
				height: "auto",
				width: "100%",
				aspectRatio: "4/3",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div id="placeholder_spinner"></div>
		</div>
	);
	return (
		<>
			<HeaderPf />
			<motion.main
				aria-label="Sekcja główna podstrony"
				className="projectpage"
				initial={{
					opacity: 0,
					y: "4%",
				}}
				animate={{ opacity: 1, y: 0, background: "#B03B25" }}
				exit={{ opacity: 1, y: "-4%" }}
				transition={{ duration: 0.5, background: "#B03B25" }}
			>
				<motion.section
					aria-label="Sekcja z grafikami"
					className="projectpageinner"
					initial={{
						opacity: 0,
					}}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.4 }}
				>
					<Link className="closebutton" to="/"></Link>
					<div className="singleprojectgrid">
						<figure className="singleprojectgrid_box" role="group">
							<ProgressiveImage src={imgissei4} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Rollup konferencyjny" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Broszura promocyjna z programem konferencji"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgissei1} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img
											src={src}
											alt="Broszura promocyjna z programem konferencji"
										/>
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Teczka konferencyjna"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgissei2} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Teczka konferencyjna" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Zaproszenie"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgissei3} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Zaproszenie" />
									);
								}}
							</ProgressiveImage>
						</figure>
					</div>
					<AnimationLine />
					<div className="singleprojectdesc">
						<p>
							Materiały promocyjne dla konferencji naukowej: "The 15th
							International Conference of ISSEI. What's <i>New</i> in the New
							Europe? Redefining Culture, Politics, Identity. Lodz, Poland"
							<br />
							<br />
							<small>Uniwersytet Łodzki</small>
						</p>
					</div>
				</motion.section>
			</motion.main>
			<NavProjectsPf />
			<FooterPf />
		</>
	);
}

export default ProjectPf002;
