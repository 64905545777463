import React from "react";
import { Route, Routes, Link, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, FreeMode, Grid } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "../index.css";

import ProjectPf001 from "../pages/project001";
import ProjectPf002 from "../pages/project002";
import ProjectPf003 from "../pages/project003";
import ProjectPf004 from "../pages/project004";
import ProjectPf005 from "../pages/project005";
import ProjectPf006 from "../pages/project006";
import ProjectPf007 from "../pages/project007";
import ProjectPf008 from "../pages/project008";
import ProjectPf009 from "../pages/project009";
import ProjectPf010 from "../pages/project010";
import ProjectPf011 from "../pages/project011";
import ProjectPf012 from "../pages/project012";
import ProjectPf013 from "../pages/project013";
import ProjectPf014 from "../pages/project014";

import img1 from "../assets/images/katalog-lodzkie-circle.webp";
import img2 from "../assets/images/issei-circle.webp";
import img3 from "../assets/images/rotwl-circle.webp";
import img4 from "../assets/images/kit-circle.webp";
import img5 from "../assets/images/piwny-circle.webp";
import img6 from "../assets/images/osadnicy-circle.webp";
import img7 from "../assets/images/visit-circle.webp";
import img8 from "../assets/images/obiekty-techniki-circle.webp";
import img9 from "../assets/images/cx-circle.webp";
import img10 from "../assets/images/klientocentryczni-circle.webp";
import img11 from "../assets/images/kancelaria-circle.webp";
import img12 from "../assets/images/sprawozdanie-circle.webp";
import img13 from "../assets/images/stn-circle.webp";
import img14 from "../assets/images/umed-circle.webp";

export default function NavProjectsPf() {
	const location = useLocation();
	return (
		<>
			<motion.nav
				className="navprojects"
				role="main"
				aria-label="Sekcja główna portfolio"
				initial={{
					opacity: 0,
				}}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.4 }}
			>
				<div className="navprojects_text">Wybierz projekt:</div>
				<div className="navprojects_box">
					<Swiper
						modules={[Grid, FreeMode, Navigation, Pagination]}
						// className="sample-slider"

						slidesPerView={3.5}
						spaceBetween={16}
						breakpoints={{
							400: {
								slidesPerView: 4.5,
							},
							700: {
								slidesPerView: 5.5,
							},
							1000: {
								slidesPerView: 6.5,
							},
							1100: {
								slidesPerView: 7.5,
							},
							1200: {
								slidesPerView: 8.5,
							},
							1300: {
								slidesPerView: 9.5,
							},
							1400: {
								slidesPerView: 10.5,
							},
							1500: {
								slidesPerView: 13.5,
							},
						}}
						freeMode={true}
						// loop={true}
					>
						<SwiperSlide>
							<Link to="/osadnicy">
								<LazyLoadImage src={img6} alt="Strona internetowa Osadnicy" />
							</Link>
						</SwiperSlide>
						<SwiperSlide>
							<Link to="/konferencja-issei">
								<LazyLoadImage
									src={img2}
									alt="The 15th International Conference of ISSEI"
								/>
							</Link>
						</SwiperSlide>
						<SwiperSlide>
							<Link to="/rotwl-identyfikacja">
								<LazyLoadImage
									src={img3}
									alt="Regionalna Organizacja Turystyczna Województwa Łódzkiego"
								/>
							</Link>
						</SwiperSlide>
						<SwiperSlide>
							<Link to="/obiekty-techniki">
								<LazyLoadImage
									src={img8}
									alt="Obiekty techniki województwa łódzkiego"
								/>
							</Link>
						</SwiperSlide>
						<SwiperSlide>
							<Link to="/kancelaria">
								<LazyLoadImage
									src={img11}
									alt="Strona internetowa Kancelarii Radcy Prawnego i Mediatora Anna Łęgosz"
								/>
							</Link>
						</SwiperSlide>
						<SwiperSlide>
							<Link to="/katalog-lodzkie">
								<LazyLoadImage
									src={img1}
									alt="Przewodnik turystyczy po województwie łódzkim"
								/>
							</Link>
						</SwiperSlide>
						<SwiperSlide>
							<Link to="/studenckie-towarzystwo-naukowe">
								<LazyLoadImage
									src={img13}
									alt="Strona internetowa Studenckie Towarzystwo Naukowe Uniwersytetu Medycznego w Łodzi"
								/>
							</Link>
						</SwiperSlide>
						<SwiperSlide>
							<Link to="/uniwersytet-medyczny">
								<LazyLoadImage
									src={img14}
									alt="Strony internetowe Uniwersytetu Medycznego w Łodzi"
								/>
							</Link>
						</SwiperSlide>
						<SwiperSlide>
							<Link to="/cx-institute">
								<LazyLoadImage
									src={img9}
									alt="Strona internetowa CX Institute"
								/>
							</Link>
						</SwiperSlide>
						<SwiperSlide>
							<Link to="/sprawozdanie-2020">
								<LazyLoadImage
									src={img12}
									alt="Sprawozdanie z działalności zarządu Związku Województw RP"
								/>
							</Link>
						</SwiperSlide>
						<SwiperSlide>
							<Link to="/kultura-i-turystyka">
								<LazyLoadImage
									src={img4}
									alt="Identyfikacja wizualna konferencji Kultura i Turystyka"
								/>
							</Link>
						</SwiperSlide>
						<SwiperSlide>
							<Link to="/klientocentryczni">
								<LazyLoadImage
									src={img10}
									alt="Strona internetowa Klientocentryczni"
								/>
							</Link>
						</SwiperSlide>
						<SwiperSlide>
							<Link to="/piwny-marketing">
								<LazyLoadImage
									src={img5}
									alt="Identyfikacja wizualna marki Piwny Marketing"
								/>
							</Link>
						</SwiperSlide>
						<SwiperSlide>
							<Link to="/visit-lodzkie">
								<LazyLoadImage
									src={img7}
									alt="Katalog ofert turystycznych Visit Łódzkie"
								/>
							</Link>
						</SwiperSlide>
					</Swiper>
				</div>
			</motion.nav>
			<AnimatePresence mode="wait">
				<Routes location={location} key={location.pathname}>
					<Route exact path="projekt001" element={<ProjectPf001 />} />
					<Route exact path="projekt002" element={<ProjectPf002 />} />
					<Route exact path="projekt003" element={<ProjectPf003 />} />
					<Route exact path="projekt004" element={<ProjectPf004 />} />
					<Route exact path="projekt005" element={<ProjectPf005 />} />
					<Route exact path="projekt006" element={<ProjectPf006 />} />
					<Route exact path="projekt007" element={<ProjectPf007 />} />
					<Route exact path="projekt008" element={<ProjectPf008 />} />
					<Route exact path="projekt009" element={<ProjectPf009 />} />
					<Route exact path="projekt010" element={<ProjectPf010 />} />
					<Route exact path="projekt011" element={<ProjectPf011 />} />
					<Route exact path="projekt012" element={<ProjectPf012 />} />
					<Route exact path="projekt013" element={<ProjectPf013 />} />
					<Route exact path="projekt014" element={<ProjectPf014 />} />
				</Routes>
			</AnimatePresence>
		</>
	);
}
