import { Link } from "react-router-dom";
import React from "react";

import { motion, useScroll, useSpring } from "framer-motion";

function HeaderPf() {
	const { scrollYProgress } = useScroll();
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001,
	});

	return (
		<header>
			<Link className="logolink" to="/">
				<span className="logo">Piotr Dolata</span>
				<span className="logo_under">web&print design</span>
			</Link>
			<nav className="mainnav">
				<ul>
					<li className="mobilehide">
						<Link className="marker" to="mailto:kontakt@piotrekd.pl">
							kontakt@piotrekd.pl
						</Link>
					</li>
					<li>
						<Link to="mailto:kontakt@piotrekd.pl">
							{/* <div className="person_box"></div> */}
							<div className="person_piotr_box"></div>
						</Link>
					</li>
				</ul>
			</nav>
			<motion.div className="progressbar" style={{ scaleX }} />
		</header>
	);
}

export default HeaderPf;
