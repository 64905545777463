import React from "react";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import HeaderPf from "../components/common/header";
import NavProjectsPf from "../hooks/AnimatonRoutesSecond";
import FooterPf from "../components/common/footer";

import imgosadnicy001 from "../assets/images/osadnicy001.webp";
import imgosadnicy002 from "../assets/images/osadnicy002.webp";
import imgosadnicy003 from "../assets/images/osadnicy003.webp";
import imgosadnicy004 from "../assets/images/osadnicy004.webp";
import imgosadnicy005 from "../assets/images/osadnicy005.webp";
import imgosadnicy006 from "../assets/images/osadnicy006.webp";
import imgosadnicy007 from "../assets/images/osadnicy007.webp";
import imgosadnicy008 from "../assets/images/osadnicy008.webp";

import AnimationLine from "../hooks/AnimateLine";

function ProjectPf006() {
	const placeholder = (
		<div
			style={{
				backgroundColor: "#f2f2f2",
				height: "auto",
				width: "100%",
				aspectRatio: "4/3",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div id="placeholder_spinner"></div>
		</div>
	);

	return (
		<>
			<HeaderPf />
			<motion.main
				aria-label="Sekcja główna podstrony"
				className="projectpage"
				initial={{
					opacity: 0,
					y: "4%",
				}}
				animate={{ opacity: 1, y: 0, background: "#333333" }}
				exit={{ opacity: 1, y: "-4%" }}
				transition={{ duration: 0.5, background: "#333333" }}
			>
				<motion.section
					aria-label="Sekcja z grafikami"
					className="projectpageinner"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.4 }}
				>
					<Link className="closebutton" to="/"></Link>
					<div className="singleprojectgrid">
						<figure className="singleprojectgrid_box" role="group">
							<a href="https://osadnicy.info/">
								<ProgressiveImage src={imgosadnicy001} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="Strona internetowa osadnicy.info" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Ratusz w Aleksandrowie Łódzkim"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://osadnicy.info/miejsca/ratusze/ratusz-miejski-w-aleksandrowie-lodzkim/">
								<ProgressiveImage src={imgosadnicy002} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img
												src={src}
												alt="osadnicy.info - ratusz w Aleksandrowie Łódzkim"
											/>
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Osada fabryczna Poddębice"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://osadnicy.info/miejsca/osady-fabryczne/osada-sukiennicza-poddebice/">
								<ProgressiveImage src={imgosadnicy003} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img
												src={src}
												alt="osadnicy.info - osada fabryczna Poddębice"
											/>
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Kościół w Zelowie"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://osadnicy.info/miejsca/koscioly/kosciol-ewangelicko-augsburski-w-zelowie/">
								<ProgressiveImage src={imgosadnicy004} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="osadnicy.info - kościół w Zelowie" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<a href="https://osadnicy.info/miejsca/fabryki/mlyn-wodny-i-fabryka-wlokiennicza-huberta-neuvillea-w-emilianowie/">
								<ProgressiveImage src={imgosadnicy005} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="osadnicy.info - młyn w Emilianowie" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Izabela Lasocka"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://osadnicy.info/postacie/izabela-lasocka/">
								<ProgressiveImage src={imgosadnicy006} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="osadnicy.info - Izabela Lasocka" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Szlakiem młynów nad Grabią"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://osadnicy.info/wycieczki/szlakiem-mlynow-nad-grabia/">
								<ProgressiveImage src={imgosadnicy007} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img
												src={src}
												alt="osadnicy.info - szlakiem młynów nad Grabią"
											/>
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<a href="https://osadnicy.info/">
								<ProgressiveImage src={imgosadnicy008} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="osadnicy.info" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
					</div>
					<AnimationLine />
					<div className="singleprojectdesc">
						<p>
							Strona internetowa poświęcona tematyce XIX-wiecznego osadnictwa
							niemieckiego na obszarze środkowej Polski. Prace obejmowały
							koncepcję, kwerendę źródłową, fotografię miejsc związanych z
							osadnictwem (stare domy, kościoły, cmentarze, zabudowania
							fabryczne i wiele innych), zaprojektowanie designu strony
							internetowej oraz wykonanie w oparciu o system Wordpress. Oprócz
							charakterystyki zachowanych obiektów strona zawiera biogramy
							wybranych postaci oraz propozycje tras rowerowych.
							<br />
							<br />
							<small>Projekt własny</small>
						</p>
					</div>
				</motion.section>
			</motion.main>
			<NavProjectsPf />
			<FooterPf />
		</>
	);
}

export default ProjectPf006;
