import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import HomePf from "../pages/home";

import ProjectPf001 from "../pages/project001";
import ProjectPf002 from "../pages/project002";
import ProjectPf003 from "../pages/project003";
import ProjectPf004 from "../pages/project004";
import ProjectPf005 from "../pages/project005";
import ProjectPf006 from "../pages/project006";
import ProjectPf007 from "../pages/project007";
import ProjectPf008 from "../pages/project008";
import ProjectPf009 from "../pages/project009";
import ProjectPf010 from "../pages/project010";
import ProjectPf011 from "../pages/project011";
import ProjectPf012 from "../pages/project012";
import ProjectPf013 from "../pages/project013";
import ProjectPf014 from "../pages/project014";

export default function AnimatedRoutes() {
	const location = useLocation();
	return (
		<AnimatePresence mode="wait">
			<Routes location={location} key={location.pathname}>
				<Route exact path="/*" element={<HomePf />} />
				<Route exact path="katalog-lodzkie" element={<ProjectPf001 />} />
				<Route exact path="konferencja-issei" element={<ProjectPf002 />} />
				<Route exact path="rotwl-identyfikacja" element={<ProjectPf003 />} />
				<Route exact path="kultura-i-turystyka" element={<ProjectPf004 />} />
				<Route exact path="piwny-marketing" element={<ProjectPf005 />} />
				<Route exact path="osadnicy" element={<ProjectPf006 />} />
				<Route exact path="visit-lodzkie" element={<ProjectPf007 />} />
				<Route exact path="obiekty-techniki" element={<ProjectPf008 />} />
				<Route exact path="cx-institute" element={<ProjectPf009 />} />
				<Route exact path="klientocentryczni" element={<ProjectPf010 />} />
				<Route exact path="kancelaria" element={<ProjectPf011 />} />
				<Route exact path="sprawozdanie-2020" element={<ProjectPf012 />} />
				<Route
					exact
					path="studenckie-towarzystwo-naukowe"
					element={<ProjectPf013 />}
				/>
				<Route exact path="uniwersytet-medyczny" element={<ProjectPf014 />} />
			</Routes>
		</AnimatePresence>
	);
}
