import React from "react";

import icon2 from "../../assets/icons/toolai.svg";
import icon4 from "../../assets/icons/toolcorel.svg";
import icon7 from "../../assets/icons/toolcss.svg";
import icon5 from "../../assets/icons/toolfigma.svg";
import icon6 from "../../assets/icons/toolhtml.svg";
import icon3 from "../../assets/icons/toolid.svg";
import icon9 from "../../assets/icons/tooljs.svg";
import icon1 from "../../assets/icons/toolps.svg";
import icon10 from "../../assets/icons/toolreact.svg";
import icon8 from "../../assets/icons/toolwp.svg";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, FreeMode, Grid } from "swiper/modules";

function ToolsPf() {
	return (
		<Swiper
			modules={[Grid, FreeMode, Navigation, Pagination]}
			className="sample-slider"
			slidesPerView={2.5}
			slidesOffsetBefore={48}
			slidesOffsetAfter={48}
			spaceBetween={16}
			// centeredSlides={true}
			breakpoints={{
				576: {
					slidesPerView: 3.5,
					slidesOffsetBefore: 48,
					slidesOffsetAfter: 48,
				},
				768: {
					slidesPerView: 4.5,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0,
				},
				992: {
					slidesPerView: 6,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0,
				},
				1200: {
					slidesPerView: 8,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0,
				},
				1400: {
					slidesPerView: 10,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0,
				},
			}}
			freeMode={true}
		>
			<SwiperSlide>
				<div className="mytools_box_slide">
					<img
						alt="Adobe Photoshop"
						src={icon1}
						width="40"
						height="auto"
						loading="lazy"
					/>
					<p class="mytools_box_slide_desc">Photoshop</p>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="mytools_box_slide">
					<img
						alt="Adobe Illustrator"
						src={icon2}
						width="40"
						height="auto"
						loading="lazy"
					/>
					<p class="mytools_box_slide_desc">Illustrator</p>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="mytools_box_slide">
					<img
						alt="Adobe InDesign"
						src={icon3}
						width="40"
						height="auto"
						loading="lazy"
					/>
					<p class="mytools_box_slide_desc">InDesign</p>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="mytools_box_slide">
					<img
						alt="Corel Draw"
						src={icon4}
						width="36"
						height="auto"
						loading="lazy"
					/>
					<p class="mytools_box_slide_desc">CorelDraw</p>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="mytools_box_slide">
					<img
						class=""
						alt="Figma"
						src={icon5}
						width="30"
						height="auto"
						loading="lazy"
					/>
					<p class="mytools_box_slide_desc">Figma</p>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="mytools_box_slide">
					<img
						class=""
						alt="HTML"
						src={icon6}
						width="40"
						height="auto"
						loading="lazy"
					/>
					<p class="mytools_box_slide_desc">HTML</p>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="mytools_box_slide">
					<img
						class=""
						alt="CSS"
						src={icon7}
						width="40"
						height="auto"
						loading="lazy"
					/>
					<p class="mytools_box_slide_desc">CSS</p>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="mytools_box_slide">
					<img
						class=""
						alt="WordPress"
						src={icon8}
						width="40"
						height="40"
						loading="lazy"
					/>
					<p class="mytools_box_slide_desc">WordPress</p>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="mytools_box_slide">
					<img
						class=""
						alt="JavaScript"
						src={icon9}
						width="40"
						height="auto"
						loading="lazy"
					/>
					<p class="mytools_box_slide_desc">JavaScript</p>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="mytools_box_slide">
					<img
						class=""
						alt="React"
						src={icon10}
						width="40"
						height="auto"
						loading="lazy"
					/>
					<p class="mytools_box_slide_desc">React</p>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
		</Swiper>
	);
}

export default ToolsPf;
