import React from "react";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import HeaderPf from "../components/common/header";
import NavProjectsPf from "../hooks/AnimatonRoutesSecond";
import FooterPf from "../components/common/footer";

import imgobiekty1 from "../assets/images/obiekty-techniki-001.webp";
import imgobiekty2 from "../assets/images/obiekty-techniki-002.webp";
import imgobiekty3 from "../assets/images/obiekty-techniki-003.webp";
import imgobiekty4 from "../assets/images/obiekty-techniki-004.webp";
import imgobiekty5 from "../assets/images/obiekty-techniki-005.webp";
import imgobiekty6 from "../assets/images/obiekty-techniki-006.webp";
import imgobiekty7 from "../assets/images/obiekty-techniki-007.webp";
import imgobiekty8 from "../assets/images/obiekty-techniki-008.webp";
import imgobiekty9 from "../assets/images/obiekty-techniki-009.webp";
import imgobiekty10 from "../assets/images/obiekty-techniki-010.webp";

import AnimationLine from "../hooks/AnimateLine";

function ProjectPf008() {
	const placeholder = (
		<div
			style={{
				backgroundColor: "#f2f2f2",
				height: "auto",
				width: "100%",
				aspectRatio: "4/3",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div id="placeholder_spinner"></div>
		</div>
	);

	return (
		<>
			<HeaderPf />
			<motion.main
				aria-label="Sekcja główna podstrony"
				className="projectpage"
				initial={{
					opacity: 0,
					y: "4%",
				}}
				animate={{ opacity: 1, y: 0, background: "#7E7E7E" }}
				exit={{ opacity: 1, y: "-4%" }}
				transition={{ duration: 0.5, background: "#7E7E7E" }}
			>
				<motion.section
					aria-label="Sekcja z grafikami"
					className="projectpageinner"
					initial={{
						opacity: 0,
					}}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.4 }}
				>
					<Link className="closebutton" to="/"></Link>
					<div className="singleprojectgrid">
						<figure className="singleprojectgrid_box" role="group">
							<ProgressiveImage src={imgobiekty1} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img
											src={src}
											alt="Obiekty techniki województwa łódzkiego"
										/>
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Segregator na serię zeszytów"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgobiekty2} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Segregator na serię zeszytów" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Koleje i tramwaje"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgobiekty3} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img
											src={src}
											alt="Obiekty techniki województwa łódzkiego - Koleje i tramwaje"
										/>
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Współczesne zakłady przemysłowe"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgobiekty9} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img
											src={src}
											alt="Obiekty techniki województwa łódzkiego - Współczesne zakłady przemysłowe"
										/>
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Młyny nad Grabią - rozkładówka"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgobiekty5} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img
											src={src}
											alt="Obiekty techniki województwa łódzkiego - Młyny nad Grabią"
										/>
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: [
											"VNB-125 „Herbrand” – pierwszy tramwaj kursujący po ulicach Łodzi",
										],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgobiekty6} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img
											src={src}
											alt="VNB-125 „Herbrand” – pierwszy tramwaj kursujący po ulicach Łodzi"
										/>
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Wiatrak „koźlak” - schemat konstrukcyjny"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgobiekty7} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img
											src={src}
											alt="Wiatrak „koźlak” - schemat konstrukcyjny"
										/>
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Cukrownie, gorzelnie i browary - rozkładówka"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgobiekty8} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img
											src={src}
											alt="Cukrownie, gorzelnie i browary - rozkładówka"
										/>
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Architektura przemysłowa w Łodzi - rozkładówka"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgobiekty4} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img
											src={src}
											alt="Architektura przemysłowa w Łodzi - rozkładówka"
										/>
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<ProgressiveImage src={imgobiekty10} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img
											src={src}
											alt="Bierz aparat i ruszaj w drogę! - zdjęcie ilustracyjne"
										/>
									);
								}}
							</ProgressiveImage>
						</figure>
					</div>
					<AnimationLine />
					<div className="singleprojectdesc">
						<p>
							Projekt serii zeszytów o tematyce historyczno-krajoznawczej
							"Obiekty techniki województwa łódzkiego". Każdy zeszyt poświęcony
							był odrębnej kategorii obiektów: Budynki gospodarcze, Cukrownie,
							gorzelnie i browary, Fabryki, Koleje i tramwaje, Kopalnie i
							kamieniołomy, Młyny wodne i wiatrowe, Muzea i ekspozycje oraz
							Współczesne zakłady przemysłowe.
							<br />
							<br />
							<small>Urząd Marszałkowski Województwa Łódzkiego</small>
						</p>
					</div>
				</motion.section>
			</motion.main>
			<NavProjectsPf />
			<FooterPf />
		</>
	);
}

export default ProjectPf008;
