import React from "react";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import HeaderPf from "../components/common/header";
import NavProjectsPf from "../hooks/AnimatonRoutesSecond";
import FooterPf from "../components/common/footer";

import imgvisit1 from "../assets/images/visit001.webp";
import imgvisit2 from "../assets/images/visit002.webp";
import imgvisit3 from "../assets/images/visit003.webp";
import imgvisit4 from "../assets/images/visit004.webp";
import imgvisit5 from "../assets/images/visit005.webp";
import imgvisit6 from "../assets/images/visit006.webp";

import AnimationLine from "../hooks/AnimateLine";

function ProjectPf007() {
	const placeholder = (
		<div
			style={{
				backgroundColor: "#f2f2f2",
				height: "auto",
				width: "100%",
				aspectRatio: "4/3",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div id="placeholder_spinner"></div>
		</div>
	);

	return (
		<>
			<HeaderPf />
			<motion.main
				aria-label="Sekcja główna podstrony"
				className="projectpage"
				initial={{
					opacity: 0,
					y: "4%",
				}}
				animate={{ opacity: 1, y: 0, background: "#b38c2f" }}
				exit={{ opacity: 1, y: "-4%" }}
				transition={{ duration: 0.5, background: "#b38c2f" }}
			>
				<motion.section
					aria-label="Sekcja z grafikami"
					className="projectpageinner"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.4 }}
				>
					<Link className="closebutton" to="/"></Link>
					<div className="singleprojectgrid">
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Visit Łódzkie"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgvisit1} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Visit Łódzkie - okładka" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Visit Łódzkie - Feel more"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgvisit2} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Visit Łódzkie - Feel more" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Pierwsza randka z Łodzią"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgvisit3} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img
											src={src}
											alt="Visit Łódzkie - Pierwsza randka z Łodzią"
										/>
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Oferty z Łodzi"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgvisit4} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Visit Łódzkie - Oferty z Łodzi" />
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Visit Łódzkie - Wyprawa z nurtem Pilicy"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgvisit5} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img
											src={src}
											alt="Visit Łódzkie - Wyprawa z nurtem Pilicy"
										/>
									);
								}}
							</ProgressiveImage>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["W drogę!"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<ProgressiveImage src={imgvisit6} placeholder="">
								{(src, loading) => {
									return loading ? (
										placeholder
									) : (
										<img src={src} alt="Visit Łódzkie - W drogę" />
									);
								}}
							</ProgressiveImage>
						</figure>
					</div>
					<AnimationLine />
					<div className="singleprojectdesc">
						<p>
							Projekt i skład katalogu ofert turystycznych z województwa
							łódzkiego Visit Łódzkie
							<br />
							<br />
							<small>
								Regionalna Organizacja Turystyczna Województwa Łódzkiego
							</small>
						</p>
					</div>
				</motion.section>
			</motion.main>
			<NavProjectsPf />
			<FooterPf />
		</>
	);
}

export default ProjectPf007;
