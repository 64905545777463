import React from "react";

import logo1 from "../../assets/logos/clientslodzkie.svg";
import logo2 from "../../assets/logos/clientsrotwl.svg";
import logo3 from "../../assets/logos/clientsumed.svg";
import logo4 from "../../assets/logos/clientsul.svg";
import logo5 from "../../assets/logos/clientsklientocentryczni.svg";
import logo6 from "../../assets/logos/clientsfumed.svg";
import logo7 from "../../assets/logos/clientscx.svg";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, FreeMode } from "swiper/modules";

function ClientsPf() {
	return (
		<Swiper
			modules={[FreeMode, Navigation, Pagination]}
			className="sample-slider"
			slidesPerView={1.6}
			slidesOffsetBefore={48}
			slidesOffsetAfter={48}
			// centeredSlides={true}
			breakpoints={{
				576: {
					slidesPerView: 2.5,
					slidesOffsetBefore: 48,
					slidesOffsetAfter: 48,
				},
				768: {
					slidesPerView: 3.5,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0,
				},
				992: {
					slidesPerView: 4.2,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0,
				},
				1200: {
					slidesPerView: 5.2,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0,
				},
				1400: {
					slidesPerView: 6.2,
					slidesOffsetBefore: 0,
					slidesOffsetAfter: 0,
				},
			}}
			spaceBetween={16}
			freeMode={true}
		>
			<SwiperSlide>
				<div className="clients_box_slide">
					<img
						alt="Urząd Marszałkowski Województwa Łódzkiego"
						src={logo1}
						width="auto"
						height="60"
						loading="lazy"
					/>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="clients_box_slide">
					<img
						alt="Regionalna Organizacja Turystyczna Województwa Łódzkiego"
						src={logo2}
						width="auto"
						height="60"
						loading="lazy"
					/>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="clients_box_slide">
					<img
						alt="Uniwersytet Medyczny w Łodzi"
						src={logo3}
						width="auto"
						height="60"
						loading="lazy"
					/>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="clients_box_slide">
					<img
						alt="Uniwersytet Łódzki"
						src={logo4}
						width="auto"
						height="60"
						loading="lazy"
					/>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="clients_box_slide">
					<img
						alt="Fundacja dla Uniwersytetu Medycznego"
						src={logo6}
						width="auto"
						height="60"
						loading="lazy"
					/>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="clients_box_slide">
					<img
						alt="Klientocentryczni"
						src={logo5}
						width="auto"
						height="60"
						loading="lazy"
					/>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
			<SwiperSlide>
				<div className="clients_box_slide">
					<img
						alt="CX Institute"
						src={logo7}
						width="auto"
						height="60"
						loading="lazy"
					/>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</div>
			</SwiperSlide>
		</Swiper>
	);
}

export default ClientsPf;
