import React from "react";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import HeaderPf from "../components/common/header";
import NavProjectsPf from "../hooks/AnimatonRoutesSecond";
import FooterPf from "../components/common/footer";

import imgcx1 from "../assets/images/cx000.webp";
import imgcx2 from "../assets/images/cx001.webp";
import imgcx3 from "../assets/images/cx002.webp";
import imgcx4 from "../assets/images/cx003.webp";
import imgcx5 from "../assets/images/cx004.webp";
import imgcx6 from "../assets/images/cx005.webp";

import AnimationLine from "../hooks/AnimateLine";

function ProjectPf009() {
	const placeholder = (
		<div
			style={{
				backgroundColor: "#f2f2f2",
				height: "auto",
				width: "100%",
				aspectRatio: "4/3",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div id="placeholder_spinner"></div>
		</div>
	);

	return (
		<>
			<HeaderPf />
			<motion.main
				aria-label="Sekcja główna podstrony"
				className="projectpage"
				initial={{
					opacity: 0,
					y: "4%",
				}}
				animate={{ opacity: 1, y: 0, background: "#fece41" }}
				exit={{ opacity: 1, y: "-4%" }}
				transition={{ duration: 0.5, background: "#fece41" }}
			>
				<motion.section
					aria-label="Sekcja z grafikami"
					className="projectpageinner"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.4 }}
				>
					<Link className="closebutton" to="/"></Link>
					<div className="singleprojectgrid">
						<figure className="singleprojectgrid_box" role="group">
							<a href="https://cxinstitute.pl/">
								<ProgressiveImage src={imgcx1} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="cxinstitute.pl" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Strona główna"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://cxinstitute.pl/">
								<ProgressiveImage src={imgcx2} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="cxinstitute.pl - Strona główna" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Cennik"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://cxinstitute.pl/cennik/">
								<ProgressiveImage src={imgcx3} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="cxinstitute.pl - Cennik" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Artykuły"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://cxinstitute.pl/akademia-cx/artykuły/">
								<ProgressiveImage src={imgcx4} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="cxinstitute.pl - Artykuły" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Audyt CX/CX 360°"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://cxinstitute.pl/uslugi/cx-audyt/">
								<ProgressiveImage src={imgcx5} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="cxinstitute.pl - Audyt CX" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<a href="https://cxinstitute.pl/">
								<ProgressiveImage src={imgcx6} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="cxinstitute.pl" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
					</div>
					<AnimationLine />
					<div className="singleprojectdesc">
						<p>
							Projekt i&nbsp;budowa strony internetowej CX Institute, w oparciu
							o indywiduwalny szalbon i&nbsp;system Wordpress.
							<br />
							<br />
							<small>CX Institute</small>
						</p>
					</div>
				</motion.section>
			</motion.main>
			<NavProjectsPf />
			<FooterPf />
		</>
	);
}

export default ProjectPf009;
