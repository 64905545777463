import React from "react";
import { motion } from "framer-motion";
import TypeIt from "typeit-react";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-graceful-image";

import HeaderPf from "../components/common/header";
import NavProjectsPf from "../hooks/AnimatonRoutesSecond";
import FooterPf from "../components/common/footer";

import imgstn1 from "../assets/images/stn001.webp";
import imgstn2 from "../assets/images/stn002.webp";
import imgstn3 from "../assets/images/stn003.webp";
import imgstn4 from "../assets/images/stn004.webp";
import imgstn5 from "../assets/images/stn005.webp";
import imgstn6 from "../assets/images/stn006.webp";

import AnimationLine from "../hooks/AnimateLine";

function ProjectPf013() {
	const placeholder = (
		<div
			style={{
				backgroundColor: "#f2f2f2",
				height: "auto",
				width: "100%",
				aspectRatio: "4/3",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div id="placeholder_spinner"></div>
		</div>
	);

	return (
		<>
			<HeaderPf />
			<motion.main
				aria-label="Sekcja główna podstrony"
				className="projectpage"
				initial={{
					opacity: 0,
					y: "4%",
				}}
				animate={{ opacity: 1, y: 0, background: "#40D666" }}
				exit={{ opacity: 1, y: "-4%" }}
				transition={{ duration: 0.5, background: "#40D666" }}
			>
				<motion.section
					aria-label="Sekcja z grafikami"
					className="projectpageinner"
					initial={{
						opacity: 0,
					}}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.4 }}
				>
					<Link className="closebutton" to="/"></Link>
					<div className="singleprojectgrid">
						<figure className="singleprojectgrid_box" role="group">
							<a href="https://stn.umed.pl/">
								<ProgressiveImage src={imgstn1} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="stn.umed.pl" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Granty UMEDu"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://stn.umed.pl/granty-umedu/">
								<ProgressiveImage src={imgstn2} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="stn.umed.pl - Granty UMEDu" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>

						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Regulamin STN"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://stn.umed.pl/o-nas/regulamin-studenckiego-towarzystwa-naukowego-uniwersytetu-medycznego-w-lodzi/">
								<ProgressiveImage src={imgstn3} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="stn.umed.pl - Regulamin STN" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Zarząd STN"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://stn.umed.pl/o-nas/zarzad-studenckiego-towarzystwa-naukowego-uniwersytetu-lodzkiego/">
								<ProgressiveImage src={imgstn4} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="stn.umed.pl - Zarząd STN" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<figcaption>
								<TypeIt
									options={{
										strings: ["Studenckie Koła Naukowe"],
										speed: 10,
										waitUntilVisible: true,
									}}
								/>
							</figcaption>
							<a href="https://stn.umed.pl/studenckie-kola-naukowe/">
								<ProgressiveImage src={imgstn5} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img
												src={src}
												alt="stn.umed.pl - Studenckie Koła Naukowe"
											/>
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
						<figure className="singleprojectgrid_box" role="group">
							<a href="https://stn.umed.pl/">
								<ProgressiveImage src={imgstn6} placeholder="">
									{(src, loading) => {
										return loading ? (
											placeholder
										) : (
											<img src={src} alt="stn.umed.pl - Wersja mobilna" />
										);
									}}
								</ProgressiveImage>
							</a>
						</figure>
					</div>
					<AnimationLine />
					<div className="singleprojectdesc">
						<p>
							Projekt i&nbsp;budowa strony internetowej Studenckiego Towarzystwa
							Naukowego Uniwersytetu Medycznegoi w&nbsp;Łodzi, w&nbsp;oparciu
							o&nbsp;indywiduwalny szalbon i&nbsp;system Wordpress.
							<br />
							<br />
							<small>Piwny Marketing Tomasz Prośniak</small>
						</p>
					</div>
				</motion.section>
			</motion.main>
			<NavProjectsPf />
			<FooterPf />
		</>
	);
}

export default ProjectPf013;
