import React from "react";
import { motion, useInView } from "framer-motion";

function AnimationLine() {
	// Ref do śledzenia elementu
	const ref = React.useRef(null);
	// useInView hook - monitoruje, czy element jest w viewport
	const isInView = useInView(ref, { once: true });

	return (
		<div className="borderlinebox">
			<motion.div
				ref={ref}
				initial={{ width: "0%" }}
				animate={isInView ? { width: "100%" } : {}}
				transition={{ duration: 1 }}
				className="borderline"
			/>
		</div>
	);
}

export default AnimationLine;
